.img_fluid{
  max-width: 100%;
  height: auto;
}
.happyCat{
  background: transparent;
  margin: 0px auto;
  outline: none;
  height: 140px !important;
  overflow: hidden;
  display: inline-block;
  width: 20%;
  position: fixed;
  bottom: 20px !important;
  left: -70px !important;
}
.parrot{
  margin: 0px auto;
  outline: none;
  height: 200px !important;
  overflow: hidden;
  display: inline-block;
  width: 20%;
  position: fixed;
  bottom: 20px !important;
  right: -70px;
}
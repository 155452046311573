body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face{
  font-family: 'Black';
  src:  url('./Assets/Fonts/londrina-solid/LondrinaSolid-Black.ttf') ;
}
@font-face{
  font-family: 'Light';
  src:  url('./Assets/Fonts/londrina-solid/LondrinaSolid-Light.ttf') ;
}
@font-face{
  font-family: 'Regular';
  src:  url('./Assets/Fonts/londrina-solid/LondrinaSolid-Regular.ttf') ;
}
@font-face{
  font-family: 'Thin';
  src:  url('./Assets/Fonts/londrina-solid/LondrinaSolid-Thin.ttf') ;
}

@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');
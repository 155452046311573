.header_image {}

.about_container {
    padding: 120px 0px;
    background-image: url('../Assets/grid.svg');
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.creative_heading {
    font-family: 'Regular';
    font-size: 62px;
    line-height: 62px;
    margin: 0px;
    text-align: left;
    color: #191919;
}

.paragraph {
    font-family: 'Fredoka', sans-serif;
    font-size: 18px;
    line-height: 140%;
    margin: 0px;
    text-align: left;
    color: #3e3e3e;
    margin-top: 24px;
}

.card_petname {
    text-align: center;
    position: relative;
}


.customSlider {
    height: 515px;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: 43% !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
    right: 43% !important;
}

.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    top: 95% !important;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 20px !important;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    background-color: #84329B !important;
    color: white;
    padding: 12px 17px !important;
    height: 44px !important;
    width: 45px !important;
    border-radius: 50%;
    z-index: 99;
}

.card_main_pet {
    height: 450px !important;
}

.asset_attached {
    position: absolute;
    z-index: -1;
    top: -24px;
    left: 46px;
}

/* .rectangle{
    width: 100%;
    background-color: #C7F4F8;
    height: 100px;
    position: absolute;
    bottom: 0px;
    z-index: 999999;
}
#trapezoid {
    border-bottom: 100px solid #C7F4F8;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    height: 0;
    width: 100%;
  } */
.space_top {
    margin-top: 52px;
}

.third_div {
    padding: 140px 0px;
    background-color: #FFD100;
}

.img_div_earth {
    text-align: center;
    display: block;
}

.surveyContainer {
    padding: 120px 0px;
    background-image: url('../Assets/gridDiagonal.svg');
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.prelative {
    position: relative;
    text-align: center;
    margin-top: 220px;
}

.group_svg {
    position: absolute;
    max-width: 100%;
    height: 300px;
    left: 30%;
    top: -24%;
}

.earth {
    max-width: 100%;
    height: 800px;
}

.centerAlign {
    text-align: center !important;
}

.mb0 {
    margin-top: 0px !important;
}

.surveyHead {
    font-family: 'Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 45px;
    letter-spacing: 0.002em;
    color: #191919;
}

.surveyPara {
    font-family: 'Fredoka';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #3E3E3E;
}

.btnSurvey {
    background-color: #84329B;
    color: #fff;
    font-family: 'Regular';
    box-shadow: 4px 4px 0px #FFD100;
    font-size: 20px;
    padding: 14px 52px;
    border-radius: 4px;
    transition: .3s all ease-in;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #FFD100 !important;
    color: #000 !important;
}

.btnSurvey:hover {
    background-color: #191919;
    color: #FFD100 !important;
    text-decoration: none;
    transition: .3s all ease-in;
    box-shadow: 0px 0px 0px #FFD100;
}

.styleTabs {
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
    font-family: 'Regular';
    font-size: 24px !important;
    padding: 14px 52px;
    border-radius: 4px;
    transition: .3s all ease-in;
    text-align: center;
}

.styleTabs:hover {
    background-color: #FFD100;
    color: #000;
    border: 2px solid #000;
    font-family: 'Regular';
    font-size: 24px !important;
    border-radius: 4px;
    transition: .3s all ease-in;
    text-align: center;
}

.surveyImage {
    text-align: center;
}

.tabsSurvey {
    margin-top: 58px;
}

.mt32 {
    margin-top: 32px;
}

.mt82 {
    margin-top: 82px;
}

.mt42 {
    margin-top: 42px;
}

#rotate {
    -webkit-animation: rotation 30s infinite linear;
    animation: rotation 30s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}

.group_svg {
    margin-bottom: -60px;
    z-index: 22222;
}

.index_increase {
    z-index: -1;
}

.info_2 {
    font-family: 'Regular';
    font-size: 38px;
    line-height: 140%;
    margin: 0px;
    text-align: center;
    color: #191919;
}

.desktop_visible {
    display: block;
}

.mobile_visible {
    display: none;
}

.imgMainSurvey {
    height: auto;
}

@media only screen and (max-width: 600px) {
    .desktop_visible {
        display: none;
    }

    .mobile_visible {
        display: block;
    }

    .group_svg {
        height: 120px;
        left: 22%;
        translate: (50%, 50%);
    }

    .earth {
        height: 260px;
    }

    .happyCat {
        background: transparent;
        margin: 0px auto;
        outline: none;
        height: 96px !important;
        width: 20%;
        bottom: 10px !important;
        left: 6px !important;
    }

    .parrot {
        height: 110px !important;
        width: 32%;
        bottom: 21px !important;
        right: -18px;
    }

    .info_2 {
        font-size: 28px;
    }

    .prelative {
        margin-top: 120px;
    }

    .third_div,
    .surveyContainer {
        padding: 100px 0px;
    }

    .imgMainSurvey {
        height: 320px;
    }

    .mt28 {
        margin-top: 28px;
    }

    .paragraph {
        font-size: 18px;
        margin-top: 8px;
        font-family: 'Fredoka', sans-serif;
    }

    .creative_heading {
        font-size: 42px;
        line-height: 48px;
    }

    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        left: 33% !important;
    }

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
        right: 33% !important;
    }

    .customSlider {
        margin-top: 42px;
    }

    .text_container {
        margin-top: 0px;
    }

    .about_container {
        padding: 100px 0px;
    }

    .styleTabs {
        font-size: 18px !important;
        width: 100%;
    }

    .styleTabs:hover {
        font-size: 18px !important;
    }
}